var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-module"},[_c('CreateEntity',{attrs:{"modal-id":"create-module","kind":"module","route-back":{
        name: 'module-overview',
        query: { page: this.$route.query.page },
      },"title":_vm.labels.create_new_module,"title-placeholder":_vm.labels.title,"text-placeholder":_vm.labels.description,"required-fields":[],"required-data-set":[
        'title',
        'description',
        'excerpt',
        'color',
        'monetizationBundle',
        'kind',
        'free' ],"data-set-values":{ kind: 0, free: false }},on:{"entity-created":_vm.moduleCreated}},[_c('template',{slot:"optional-content"},[_c('div',[_c('FormulateInput',{staticClass:"horizontal-label-input",attrs:{"type":"text","name":"title","error-behavior":"blur","validation":"required","label":_vm.labels.title,"validation-messages":{
              required: ((_vm.labels.title) + " " + (_vm.labels.required.toLowerCase())),
            }},on:{"input":function($event){return _vm.updateCreateModuleData(_vm.formData)}},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})],1),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-sm-5 col-lg-4"},[_c('MainCard',{key:_vm.formData.color,attrs:{"title":_vm.formData.title,"description":_vm.formData.excerpt,"bgColor":_vm.formData.color,"image":_vm.currentMB}}),_c('FormulateInput',{staticClass:"mt-4",attrs:{"type":"color","name":"color","validation":"required","error-behavior":"blur"},on:{"input":function($event){return _vm.updateCreateModuleData(_vm.formData)}},model:{value:(_vm.formData.color),callback:function ($$v) {_vm.$set(_vm.formData, "color", $$v)},expression:"formData.color"}}),_c('FormulateInput',{staticClass:"mt-4",attrs:{"type":"select","name":"monetizationBundle","error-behavior":"blur","validation":"required","options":_vm.bundles},on:{"input":function($event){return _vm.updateCreateModuleData(_vm.formData)}},model:{value:(_vm.formData.monetizationBundle),callback:function ($$v) {_vm.$set(_vm.formData, "monetizationBundle", $$v)},expression:"formData.monetizationBundle"}})],1),_c('div',{staticClass:"col-sm-7 col-lg-8"},[_c('div',{staticClass:"description-title"},[_vm._v(" "+_vm._s(_vm.labels.description)+" ")]),_c('FormulateInput',{attrs:{"type":"text-editor","name":"description","error-behavior":"blur","validation":"required","validation-messages":{
                required: ((_vm.labels.description) + " " + (_vm.labels.required.toLowerCase())),
              },"extra-configs":{
                hideBgColorPicker: true,
                hideColorPicker: true,
              }},on:{"changed":function($event){return _vm.updateCreateData($event, 'description')}}}),_c('div',{staticClass:"description-title"},[_vm._v(" "+_vm._s(_vm.labels.excerpt)+" ")]),_c('FormulateInput',{attrs:{"type":"text-editor","name":"excerpt","error-behavior":"blur","validation":"required","validation-messages":{
                required: ((_vm.labels.excerpt) + " " + (_vm.labels.required.toLowerCase())),
              },"extra-configs":{
                hideBgColorPicker: true,
                hideColorPicker: true,
              }},on:{"changed":function($event){return _vm.updateCreateData($event, 'excerpt')}}})],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }