<template>
  <div class="create-module">
    <CreateEntity
      modal-id="create-module"
      kind="module"
      :route-back="{
        name: 'module-overview',
        query: { page: this.$route.query.page },
      }"
      @entity-created="moduleCreated"
      :title="labels.create_new_module"
      :title-placeholder="labels.title"
      :text-placeholder="labels.description"
      :required-fields="[]"
      :required-data-set="[
        'title',
        'description',
        'excerpt',
        'color',
        'monetizationBundle',
        'kind',
        'free',
      ]"
      :data-set-values="{ kind: 0, free: false }"
    >
      <template slot="optional-content">
        <div>
          <FormulateInput
            type="text"
            name="title"
            class="horizontal-label-input"
            error-behavior="blur"
            validation="required"
            v-model="formData.title"
            :label="labels.title"
            :validation-messages="{
              required: `${labels.title} ${labels.required.toLowerCase()}`,
            }"
            @input="updateCreateModuleData(formData)"
          />
        </div>
        <div class="row mt-4">
          <div class="col-sm-5 col-lg-4">
            <MainCard
              :key="formData.color"
              :title="formData.title"
              :description="formData.excerpt"
              :bgColor="formData.color"
              :image="currentMB"
            />
            <FormulateInput
              class="mt-4"
              type="color"
              name="color"
              v-model="formData.color"
              validation="required"
              error-behavior="blur"
              @input="updateCreateModuleData(formData)"
            />
            <FormulateInput
              type="select"
              name="monetizationBundle"
              class="mt-4"
              error-behavior="blur"
              validation="required"
              v-model="formData.monetizationBundle"
              :options="bundles"
              @input="updateCreateModuleData(formData)"
            />
          </div>
          <div class="col-sm-7 col-lg-8">
            <div class="description-title">
              {{ labels.description }}
            </div>
            <FormulateInput
              type="text-editor"
              name="description"
              error-behavior="blur"
              validation="required"
              :validation-messages="{
                required: `${
                  labels.description
                } ${labels.required.toLowerCase()}`,
              }"
              :extra-configs="{
                hideBgColorPicker: true,
                hideColorPicker: true,
              }"
              @changed="updateCreateData($event, 'description')"
            />

            <div class="description-title">
              {{ labels.excerpt }}
            </div>
            <FormulateInput
              type="text-editor"
              name="excerpt"
              error-behavior="blur"
              validation="required"
              :validation-messages="{
                required: `${labels.excerpt} ${labels.required.toLowerCase()}`,
              }"
              :extra-configs="{
                hideBgColorPicker: true,
                hideColorPicker: true,
              }"
              @changed="updateCreateData($event, 'excerpt')"
            />

            <!-- <div class="select-mb">
							<FormulateInput
								type="popup-select"
								name="monetizationBundle"
								error-behavior="blur"
								validation="required"
								v-model="formData.monetizationBundle"
								:title="labels.monetization_bundle"
								:validation-messages="{ required: `${labels.monetization_bundle} ${labels.required.toLowerCase()}` }"
								:button="{ action:openSelectPopup, label:labels.select }"
							/>
						</div> -->
          </div>
        </div>
      </template>
    </CreateEntity>
  </div>
</template>

<script>
import CreateEntity from "@/views/Dashboard/Common/CreateEntity";
import MainCard from "@/components/Cards/MainCard.vue";

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
const colors = require("/colors.config.json");
export default {
  name: "CreateModule",
  components: {
    CreateEntity,
    MainCard,
  },
  data() {
    return {
      formData: {
        title: "",
        color: `${colors.sienna}`,
        description: "",
        excerpt: "",
        monetizationBundle: "",
      },
      bundles: [],
    };
  },
  computed: {
    ...mapState("helper", {
      user_id: "user_id",
      admin_roles: "admin_roles",
    }),
    ...mapState("monetizationBundle", {
      monetizationBundleList: "monetizationBundleList",
    }),
    ...mapGetters("helper", ["labels"]),
    isAuthor() {
      let flag = false;
      
      if (Array.isArray(this.admin_roles) && this.admin_roles.includes("author")) flag = true;

      return flag;
    },
    currentMB() {
      for (var i = 0; i < this.bundles && this.bundles.length; i++) {
        console.log(this.bundles[i], this.formData.monetizationBundle);
        if (
          this.bundles[i].value == this.formData.monetizationBundle &&
          this.bundles[i].content &&
          this.bundles[i].content.image
        )
          return this.bundles[i].content.image;
      }
      return "";
    },
  },
  methods: {
    ...mapMutations("_module", {
      updateCreateModuleData: "updateCreateModuleData",
    }),
    ...mapActions("monetizationBundle", {
      getMonetizationBundleList: "getMonetizationBundleList",
    }),
    moduleCreated() {
      this.$emit("saved");
      this.updateCreateModuleData({
        title: "",
        color: `${colors.sienna}`,
        description: "",
        excerpt: "",
        monetizationBundle: "",
      });
    },
    openSelectPopup() {
      if (this.$route.name !== "create-module-select-mb") {
        let route = { name: "create-module-select-mb" };
        if (this.$route.query.page)
          route.query = { page: this.$route.query.page };
        this.$router.push(route);
      }
    },
    updateCreateData(value, key) {
      this.formData[key] = value;
      this.updateCreateModuleData(this.formData);
    },
  },
  mounted() {
    this.bundles = [];
    this.getMonetizationBundleList({}).then((res) => {
      if (res.success) {
        this.monetizationBundleList.forEach((bundle) => {
          this.bundles.push({
            value: bundle._id,
            label: bundle.title,
            content: bundle.content,
          });
        });

        if (this.bundles && this.bundles.length)
          this.formData.monetizationBundle = this.bundles[0].value;
      }
    });
  },
  beforeMount() {
    this.formData = this.$store.state._module.createModuleData;
  },
  beforeRouteLeave(to, from, next) {
    this.updateCreateModuleData({
      title: "",
      color: `${colors.sienna}`,
      description: "",
      excerpt: "",
      monetizationBundle: "",
    });

    this.formData = this.$store.state._module.createModuleData;

    next();
  },
  watch: {
    admin_roles() {
      if (!this.isAuthor) {
        this.$router.push({ name: "module-overview" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .formulate-input {
// 	input {
// 		height: auto!important;
// 		box-sizing: border-box!important;
// 		width: 100%!important;
// 	}
// }

.description-title {
  font-family: "Oswald", sans-serif;
  line-height: 1.5;
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 10px;
}
</style>
